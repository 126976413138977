<template>
    <div>
        <b-row class="content-header">
            <b-col class="content-header-left mb-2" cols="12" md="9">
                <b-row class="breadcrumbs-top">
                    <b-col cols="12">
                        <h2>Support</h2>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-overlay
            :show="loading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
        >
            <b-card title="Contact Form">
                <b-alert :variant="variant" show class="m-1" v-if="status">
                    <div class="alert-body">
                        <span>{{ status }}</span>
                    </div>
                </b-alert>

                <p class="m-1">
                    Please complete the form below to contact us. You can also
                    email us on:
                    <a href="mailto:support@leechall.io">support@leechall.io</a>
                    | Telegram:
                    <a href="https://t.me/ndt258" target="_blank">@ndt258</a>
                    | Channel:
                    <a href="https://t.me/leechallcom" target="_blank"
                        >https://t.me/leechallcom</a
                    >
                </p>

                <validation-observer ref="contactForm" #default="{ invalid }">
                    <b-form @submit.prevent="onSubmit">
                        <b-col cols="12">
                            <b-form-group
                                label="Name"
                                label-for="name"
                                label-cols-md="4"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="name"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="name"
                                        id="name"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        placeholder="Name"
                                        required
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                label="Email"
                                label-for="email"
                                label-cols-md="4"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        v-model="email"
                                        id="email"
                                        placeholder="Email"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        required
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                label="Subject"
                                label-for="subject"
                                label-cols-md="4"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="subject"
                                    rules="required"
                                >
                                    <b-form-input
                                        v-model="subject"
                                        id="subject"
                                        placeholder="Subject"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        required
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12">
                            <b-form-group
                                label="Message"
                                label-for="message"
                                label-cols-md="4"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="message"
                                    rules="required"
                                >
                                    <b-form-textarea
                                        v-model="message"
                                        id="message"
                                        :state="
                                            errors.length > 0 ? false : null
                                        "
                                        placeholder="Message"
                                        rows="3"
                                    />
                                    <small class="text-danger">{{
                                        errors[0]
                                    }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- <vue-recaptcha
                            class="mt-1"
                            ref="reCaptcha"
                            :sitekey="sitekey"
                            @verify="onContact"
                            @expired="onExpired"
                            size="invisible"
                        /> -->

                        <vue-hcaptcha
                            class="mt-1"
                            :sitekey="sitekey"
                            size="invisible"
                            ref="hCaptcha"
                            @verify="onContact"
                            @expired="onExpired"
                        />

                        <b-col offset-md="4">
                            <b-button
                                type="submit"
                                variant="primary"
                                class="mr-1"
                                :disabled="invalid"
                            >
                                Submit
                            </b-button>
                            <b-button type="reset" variant="outline-secondary">
                                Reset
                            </b-button>
                        </b-col>
                    </b-form>
                </validation-observer>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BAlert,
    BOverlay,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { VueRecaptcha } from "vue-recaptcha";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
export default {
    components: {
        BRow,
        BCol,
        BCard,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        ValidationProvider,
        ValidationObserver,
        required,
        VueRecaptcha,
        BAlert,
        BOverlay,
        VueHcaptcha,
    },
    data() {
        return {
            sitekey: process.env.VUE_APP_HCAPTCHA,
            name: "",
            email: "",
            subject: "",
            message: "",
            status: "",
            loading: false,
        };
    },
    methods: {
        resetForm() {
            this.name = "";
            this.email = "";
            this.subject = "";
            this.message = "";
            this.$nextTick(() => this.$refs.contactForm.reset());
        },
        onExpired() {
            this.$refs.hCaptcha.reset();
        },
        onSubmit() {
            this.loading = true;
            this.status = "";
            this.$refs.hCaptcha.execute();
        },
        onContact(response) {
            this.$store
                .dispatch("contact", {
                    name: this.name,
                    email: this.email,
                    subject: this.subject,
                    message: this.message,
                    "h-captcha-response": response,
                })
                .then((res) => {
                    if (res.data.success) {
                        this.loading = false;
                        this.variant = "success";
                        this.status = res.data.message;
                        this.resetForm();
                        this.onExpired();
                    }
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                    if (error.response.data.errors != undefined) {
                        if (
                            "h-captcha-response" in error.response.data.errors
                        ) {
                            this.variant = "danger";
                            this.status =
                                error.response.data.errors[
                                    "h-captcha-response"
                                ][0];
                        } else {
                            this.$refs.contactForm.setErrors(
                                error.response.data.errors
                            );
                        }
                    }
                    this.onExpired();
                });
        },
    },
};
</script>

<style></style>
